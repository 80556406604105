import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    branco: Palette['primary'];
  }

  interface PaletteOptions {
    branco?: PaletteOptions['primary'];
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    branco: true;
  }
}

export const LightTheme = createTheme({
  palette: {
    primary: {
      main: '#253b59',
      dark: '#0f1824',
      light: '#4a6890',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ddc07e',
      dark: '#5d4f2f',
      light: '#f4e5c3',
      contrastText: '#000000',
    },
    branco: {
      main: '#ffffff',
      dark: '#f1f1f1',
      light: '#cccccc',
      contrastText: '#666666',
    },
    background: {
      default: '#f1f1f1',
      paper: '#ffffff',
    },
  },
});
