import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Container, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useAppThemeContext } from '../../contexts';

import MapaSaoPaulo from './balanco-pronto-atendimento-sao-paulo.png';
import LogoRodape from './balanco-pronto-logo-rodape.png';
import TopoBox from './topo-box.jpg';
import TopoBoxDark from './topo-box.png';

export const QuemBalanco = () => {
  const { themeName } = useAppThemeContext();
  return (
    <div>
      <Box
        width="100vw"
        sx={{
          backgroundColor:
            themeName === 'dark' ? '#1c1c1c' : '#253b59',
          position: 'relative',
          height: '100px',
        }}
      >
        <Container 
          maxWidth="xl"
          sx={{
            position: 'relative'
          }}>
          <Box
            sx={{
              position: 'absolute',
              top: '1vw',
              left: '0vw',
              width: '100%',
              textAlign: 'center',
            }}>
            <Typography sx={{ 
              m: 1,
              fontSize: { xs: 14, md: 18, lg: 18 },
              color: '#ffffff',
              textTransform: 'uppercase',
            }}>Bem-vindo ao nosso escritório contábil Balanço Pronto</Typography>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor:
            themeName === 'dark' ? '#060c14' : '#f1f1f1',
          marginTop: '10px',
          paddingTop: '10px'
        }}
        id='quemsomos'>
        <Container 
          maxWidth="xl"
          sx={{
            backgroundColor:
              themeName === 'dark' ? '#1c1c1c' : '#ffffff'
          }}>
          <Box sx={{ position: 'relative' }}>
            <Box sx={{ p: 5 }} component="section">
              <Typography gutterBottom variant={'h5'} component={'span'} sx={{fontSize: '16px', fontWeight: '400', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
              No mundo complexo dos negócios, a gestão financeira eficaz é essencial para o sucesso a longo prazo. É aí que entramos em cena. Com orgulho, apresentamos nosso escritório contábil, um parceiro de confiança que está pronto para ajudar sua empresa a prosperar com experiência no mercado desde 1997.<br /><br />
              No coração do nosso escritório contábil está o compromisso com a excelência. Acreditamos que cada negócio é único e, portanto, oferecemos soluções sob medida para atender às necessidades específicas de nossos clientes. Seja você um empreendedor individual MEI ou ME, uma pequena empresa ou uma grande corporação, nossos serviços abrangentes são projetados para simplificar sua contabilidade, permitindo que você se concentre no crescimento do seu negócio.
              </Typography>
              <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '16px', fontWeight: '400', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
              Trabalhamos com os mais diversos tipos de segmentos empresariais, tanto de comércio como de serviços, com foco especial em empresas e profissionais de serviços como:
              </Typography>
              <Grid container spacing={4} sx={{mt:2}}>
                <Grid item xs={12} md={6} 
                  sx={{
                    backgroundColor:
                      themeName === 'dark' ? '#1c1c1c' : '#f1f1f1',
                    border:
                      themeName === 'dark' ? '3px solid #060c14' : '3px solid #ffffff',
                    position: 'relative',
                    p: 3,
                    textAlign: 'center',
                  }}>
                  <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                    Publicitários e agências de publicidade
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} 
                  sx={{
                    backgroundColor:
                      themeName === 'dark' ? '#1c1c1c' : '#f1f1f1',
                    border:
                      themeName === 'dark' ? '3px solid #060c14' : '3px solid #ffffff',
                    position: 'relative',
                    p: 3,
                    textAlign: 'center',
                  }}>
                  <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                    Produtores, assessores e organizadores de eventos
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} 
                  sx={{
                    backgroundColor:
                      themeName === 'dark' ? '#1c1c1c' : '#f1f1f1',
                    border:
                      themeName === 'dark' ? '3px solid #060c14' : '3px solid #ffffff',
                    position: 'relative',
                    p: 3,
                    textAlign: 'center',
                  }}>
                  <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                    Médicos e dentistas (clínicas e profissionais liberais)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} 
                  sx={{
                    backgroundColor:
                      themeName === 'dark' ? '#1c1c1c' : '#f1f1f1',
                    border:
                      themeName === 'dark' ? '3px solid #060c14' : '3px solid #ffffff',
                    position: 'relative',
                    p: 3,
                    textAlign: 'center',
                  }}>
                  <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                    Veterinários (clínicas e profissionais liberais)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} 
                  sx={{
                    backgroundColor:
                      themeName === 'dark' ? '#1c1c1c' : '#f1f1f1',
                    border:
                      themeName === 'dark' ? '3px solid #060c14' : '3px solid #ffffff',
                    position: 'relative',
                    p: 3,
                    textAlign: 'center',
                  }}>
                  <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                    Engenheiros, arquitetos e corretores de imóveis
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} 
                  sx={{
                    backgroundColor:
                      themeName === 'dark' ? '#1c1c1c' : '#f1f1f1',
                    border:
                      themeName === 'dark' ? '3px solid #060c14' : '3px solid #ffffff',
                    position: 'relative',
                    p: 3,
                    textAlign: 'center',
                  }}>
                  <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                    Advogados e escritórios de advocacia
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} 
                  sx={{
                    backgroundColor:
                      themeName === 'dark' ? '#1c1c1c' : '#f1f1f1',
                    border:
                      themeName === 'dark' ? '3px solid #060c14' : '3px solid #ffffff',
                    position: 'relative',
                    p: 3,
                    textAlign: 'center',
                  }}>
                  <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                    Programadores
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} 
                  sx={{
                    backgroundColor:
                      themeName === 'dark' ? '#1c1c1c' : '#f1f1f1',
                    border:
                      themeName === 'dark' ? '3px solid #060c14' : '3px solid #ffffff',
                    position: 'relative',
                    p: 3,
                    textAlign: 'center',
                  }}>
                  <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                    Youtubers
                  </Typography>
                </Grid>
              </Grid>
              <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '16px', fontWeight: '400', color: themeName === 'dark' ? '#ffffff' : '#000000', mt: 2}}>
              Entre outras (verifique com nosso atendimento)<br /><br />
              Nosso compromisso com a integridade, profissionalismo e sigilo é inabalável. Sabemos que a confiança é a base de qualquer parceria de sucesso, e estamos comprometidos em manter a mais alta ética em tudo o que fazemos.<br /><br />
              Aqui no nosso escritório, estamos prontos para ajudar você a alcançar seus objetivos financeiros e permitir que sua empresa prospere. Entre em contato conosco hoje e descubra como podemos simplificar suas operações contábeis e liberar seu tempo para se concentrar no que realmente importa - o crescimento do seu negócio.<br /><br />
              Confie em nós como seu parceiro de contabilidade e embarque em uma jornada de sucesso financeiro. Estamos ansiosos para trabalhar com você.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor:
            themeName === 'dark' ? '#060c14' : '#f1f1f1'
        }}
        id='servicos'>
        <Container 
          maxWidth="xl"
          sx={{display: { xs: 'block', md: 'block', lg: 'flex' }, p: 5}}>
          <Typography gutterBottom variant="h5" component="span" sx={{
            fontSize: { xs: '24px', md: '28px', lg: '34px' }, 
            fontWeight: '600', 
            width: { xs: '100%', md: '100%', lg: '400px' }, 
            color: themeName === 'dark' ? '#ffffff' : '#060c14'}}>
            As melhores soluções para seu negócio
          </Typography>
          <Typography gutterBottom variant="h5" component="span" sx={{
            fontSize: { xs: '16px', md: '18px', lg: '24px' }, 
            fontWeight: '300', 
            width: { xs: '100%', md: '100%', lg: '850px' },
            p: { xs: 0, md: 0, lg: 2 }, 
            ml: { xs: '0', md: '0', lg: '200px' }, 
            color: themeName === 'dark' ? '#ffffff' : '#060c14'}}>
            Com a Balanço Pronto você terá as soluções perfeitas para o seu negócio com um investimento muito mais acessível.
          </Typography>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor:
            themeName === 'dark' ? '#060c14' : '#f1f1f1'
        }}>
        <Container 
          maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item xs={6} md={4} 
              sx={{
                backgroundColor:
                  themeName === 'dark' ? '#1c1c1c' : '#ffffff',
                border:
                  themeName === 'dark' ? '3px solid #060c14' : '3px solid #f1f1f1',
                position: 'relative',
                pb: 5
              }}>
              {themeName === 'light' && (<img src={TopoBox} width='249px' height='19px' alt="Balanço Pronto Separador" />)}
              {themeName === 'dark' && (<img src={TopoBoxDark} width='249px' height='19px' alt="Balanço Pronto Separador" />)}
              <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '24px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Contabilidade precisa
              </Typography>
              <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '14px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Mantemos registros financeiros impecáveis e relatórios atualizados para garantir que você esteja em conformidade com todas as regulamentações fiscais.
              </Typography>
              <a href='https://wa.me/5511989761201' target='blank'>
                <IconButton aria-label="whatsapp" size="small" sx={{ml:1, backgroundColor: '#25a93e', color: '#ffffff', position:'absolute', bottom: 10, right: 10}}>
                  <WhatsAppIcon fontSize="large" />
                </IconButton>
              </a>
            </Grid>
            <Grid item xs={6} md={4} 
              sx={{
                backgroundColor:
                  themeName === 'dark' ? '#1c1c1c' : '#ffffff',
                border:
                  themeName === 'dark' ? '3px solid #060c14' : '3px solid #f1f1f1',
                position: 'relative',
                pb: 5
              }}>
              {themeName === 'light' && (<img src={TopoBox} width='249px' height='19px' alt="Balanço Pronto Separador" />)}
              {themeName === 'dark' && (<img src={TopoBoxDark} width='249px' height='19px' alt="Balanço Pronto Separador" />)}
              <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '24px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Planejamento tributário
              </Typography>
              <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '14px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Trabalhamos incansavelmente para otimizar sua carga tributária, economizando dinheiro e garantindo que você cumpra todas as obrigações fiscais.
              </Typography>
              <a href='https://wa.me/5511989761201' target='blank'>
                <IconButton aria-label="whatsapp" size="small" sx={{ml:1, backgroundColor: '#25a93e', color: '#ffffff', position:'absolute', bottom: 10, right: 10}}>
                  <WhatsAppIcon fontSize="large" />
                </IconButton>
              </a>
            </Grid>
            <Grid item xs={6} md={4} 
              sx={{
                backgroundColor:
                  themeName === 'dark' ? '#1c1c1c' : '#ffffff',
                border:
                  themeName === 'dark' ? '3px solid #060c14' : '3px solid #f1f1f1',
                position: 'relative',
                pb: 5
              }}>
              {themeName === 'light' && (<img src={TopoBox} width='249px' height='19px' alt="Balanço Pronto Separador" />)}
              {themeName === 'dark' && (<img src={TopoBoxDark} width='249px' height='19px' alt="Balanço Pronto Separador" />)}
              <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '24px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Consultoria financeira
              </Typography>
              <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '14px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Oferecemos orientação estratégica para ajudar a impulsionar seu negócio, desde a gestão de fluxo de caixa até a análise de investimentos.
              </Typography>
              <a href='https://wa.me/5511989761201' target='blank'>
                <IconButton aria-label="whatsapp" size="small" sx={{ml:1, backgroundColor: '#25a93e', color: '#ffffff', position:'absolute', bottom: 10, right: 10}}>
                  <WhatsAppIcon fontSize="large" />
                </IconButton>
              </a>
            </Grid>
            <Grid item xs={6} md={4} 
              sx={{
                backgroundColor:
                  themeName === 'dark' ? '#1c1c1c' : '#ffffff',
                border:
                  themeName === 'dark' ? '3px solid #060c14' : '3px solid #f1f1f1',
                position: 'relative',
                pb: 5
              }}>
              {themeName === 'light' && (<img src={TopoBox} width='249px' height='19px' alt="Balanço Pronto Separador" />)}
              {themeName === 'dark' && (<img src={TopoBoxDark} width='249px' height='19px' alt="Balanço Pronto Separador" />)}
              <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '24px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Serviços de folha de pagamento
              </Typography>
              <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '14px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Garantimos que seus funcionários sejam pagos corretamente e a tempo, mantendo você em conformidade com as leis trabalhistas.
              </Typography>
              <a href='https://wa.me/5511989761201' target='blank'>
                <IconButton aria-label="whatsapp" size="small" sx={{ml:1, backgroundColor: '#25a93e', color: '#ffffff', position:'absolute', bottom: 10, right: 10}}>
                  <WhatsAppIcon fontSize="large" />
                </IconButton>
              </a>
            </Grid>
            <Grid item xs={6} md={4} 
              sx={{
                backgroundColor:
                  themeName === 'dark' ? '#1c1c1c' : '#ffffff',
                border:
                  themeName === 'dark' ? '3px solid #060c14' : '3px solid #f1f1f1',
                position: 'relative',
                pb: 5
              }}>
              {themeName === 'light' && (<img src={TopoBox} width='249px' height='19px' alt="Balanço Pronto Separador" />)}
              {themeName === 'dark' && (<img src={TopoBoxDark} width='249px' height='19px' alt="Balanço Pronto Separador" />)}
              <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '24px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Assessoria Empresarial
              </Typography>
              <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '14px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Compreendemos os desafios únicos que as empresas enfrentam e fornecemos insights valiosos para o crescimento e a sustentabilidade do seu negócio.
              </Typography>
              <a href='https://wa.me/5511989761201' target='blank'>
                <IconButton aria-label="whatsapp" size="small" sx={{ml:1, backgroundColor: '#25a93e', color: '#ffffff', position:'absolute', bottom: 10, right: 10}}>
                  <WhatsAppIcon fontSize="large" />
                </IconButton>
              </a>
            </Grid>
            <Grid item xs={6} md={4} 
              sx={{
                backgroundColor:
                  themeName === 'dark' ? '#1c1c1c' : '#ffffff',
                border:
                  themeName === 'dark' ? '3px solid #060c14' : '3px solid #f1f1f1',
                position: 'relative',
                pb: 5
              }}>
              {themeName === 'light' && (<img src={TopoBox} width='249px' height='19px' alt="Balanço Pronto Separador" />)}
              {themeName === 'dark' && (<img src={TopoBoxDark} width='249px' height='19px' alt="Balanço Pronto Separador" />)}
              <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '24px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Recuperação de crédito
              </Typography>
              <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '14px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Nossa equipe de especialistas em recuperação identifica e recupera créditos fiscais não aproveitados, injetando capital vital em sua empresa.
              </Typography>
              <a href='https://wa.me/5511989761201' target='blank'>
                <IconButton aria-label="whatsapp" size="small" sx={{ml:1, backgroundColor: '#25a93e', color: '#ffffff', position:'absolute', bottom: 10, right: 10}}>
                  <WhatsAppIcon fontSize="large" />
                </IconButton>
              </a>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor:
            themeName === 'dark' ? '#060c14' : '#f1f1f1',
          pt: 5,
          pb: 8
        }}>
        <Container 
          maxWidth="xl">
          <Box
            sx={{
              backgroundColor:
                themeName === 'dark' ? '#333333' : '#263c58',
              position: 'relative',
              p: { xs: 3, md: 8, lg: 8 }
            }}>
            <Typography sx={{color: '#ffffff', fontSize: { xs: 20, md: 20, lg: 24 }, width: { xs: '100%', md: '300px', lg: '432px' }}}>
              Estamos presente em todo o estado para auxiliar você e seu negócio da melhor maneira.
            </Typography>
            <Box sx={{
              position: { xs: 'relative', md: 'absolute', lg: 'absolute' }, 
              top: { xs: 'auto', md: '-25px', lg: '-25px' }, 
              right: { xs: 'auto', md: '2%', lg: '2%' },
              textAlign: 'center'}}><img src={MapaSaoPaulo} width='494px' height='305px' alt="Balanço Pronto Mapa" /></Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor:
            themeName === 'dark' ? '#000000' : '#263c58',
          pt: 5,
          pb: 3,
          overflow: 'hidden'
        }}>
        <Container 
          maxWidth="xl">
          <Grid container spacing={0}>
            <Grid item xs={12} md={4}>
              <img src={LogoRodape} width='142px' height='55px' alt="Balanço Pronto Logo Rodape" />
              <Typography sx={{color: '#ffffff', fontSize: 12, backgroundColor: themeName === 'dark' ? '#000000' : '#263c58', position: 'relative', pt: 2}}>
                <hr style={{width: '50%', position: 'absolute', top: '0', left: '0'}} />
                Tenha a sua contabilidade mensal, sem se preocupar.
              </Typography>
              <div style={{display: 'flex'}}>
                <IconButton aria-label="Instagram" size="large" sx={{ml:1, color: '#ffffff'}}>
                  <InstagramIcon fontSize="large" />
                </IconButton>
                <a href='https://wa.me/5511989761201' target='blank'>
                  <IconButton aria-label="whatsapp" size="large" sx={{ml:1, color: '#ffffff'}}>
                    <WhatsAppIcon fontSize="large" />
                  </IconButton>
                </a>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{color: '#ffffff', fontSize: 18}}>
                FALE CONOSCO
              </Typography>
              <Typography sx={{color: '#ffffff', fontSize: 14, mt:2}}>
                Rua Martin de Souza Aguiar, 543<br />São Paulo - SP - 05042-000
              </Typography>
              <Typography sx={{color: '#ffffff', fontSize: 14, mt:2}}>
                sac@balançopronto.com.br
              </Typography>
              <a href='https://wa.me/5511989761201' target='blank' style={{textDecoration: 'none'}}>
                <Typography sx={{color: '#ffffff', fontSize: 14, mt:2}}>
                  11 9 5678 4567
                </Typography>
              </a>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{color: '#ffffff', fontSize: 18}}>
                SOBRE
              </Typography>
              <Typography sx={{color: '#ffffff', fontSize: 14, mt:2}}>
                Acreditamos que cada negócio é único e, portanto, oferecemos soluções sob medida para atender às necessidades específicas de nossos clientes.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};