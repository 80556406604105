import { Navigate, Route, Routes } from 'react-router-dom';
import { HomeBalanco } from '../shared/components';
import { QuemBalanco } from '../shared/components/quem somos/QuemSomos';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <HomeBalanco />
        }
      />
      <Route
        path="/quem-somos"
        element={
          <QuemBalanco />
        }
      />
      <Route path="*" element={<Navigate to="pagina-inicial" />} />
    </Routes>
  );
};
