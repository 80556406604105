import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes';
import ResponsiveAppBar from './shared/components/menu-topo/MenuTopo';
import { AppThemeProvider } from './shared/contexts';

export const App = () => {
  return (
    <AppThemeProvider>
      <BrowserRouter>
      
        <ResponsiveAppBar />
        <AppRoutes />

      </BrowserRouter>
    </AppThemeProvider>
  );
};
