import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import InstagramIcon from '@mui/icons-material/Instagram';
import StarIcon from '@mui/icons-material/Star';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useAppThemeContext } from '../../contexts';

import CloseIcon from '@mui/icons-material/Close';
import MapaSaoPaulo from './balanco-pronto-atendimento-sao-paulo.png';
import ImagemDestaqueMobile from './balanco-pronto-imagem-detaque-mobile.png';
import ImagemDestaqueTablet from './balanco-pronto-imagem-detaque-tablet.png';
import ImagemDestaque from './balanco-pronto-imagem-detaque.png';
import LogoRodape from './balanco-pronto-logo-rodape.png';
import ImagemQuemSomosMobile from './balanco-pronto-quem-somos-mobile.png';
import ImagemQuemSomos from './balanco-pronto-quem-somos.png';
import TopoBox from './topo-box.jpg';
import TopoBoxDark from './topo-box.png';

export const HomeBalanco = () => {
  const { themeName } = useAppThemeContext();
  const [visible, setVisible] = React.useState(false);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: themeName === 'dark' ? '#ffffff' : '#dcbf81',
    fontSize: 13,
    boxShadow: 'none'
  }));
  return (
    <div>
      <Box
        width="100vw"
        sx={{
          backgroundColor:
            themeName === 'dark' ? '#1c1c1c' : '#253b59',
          position: 'relative',
          height: { xs: '450px', md: '500px', lg: '691px' },
        }}
        id='home'
      >
        <Box sx={{display: { xs: 'none', md: 'none', lg: 'block' }}}><img src={ImagemDestaque} style={{ position: 'absolute', bottom: '0px', right: '0px', height: '691px', width: '741px' }} alt="Imagem banner" /></Box>
        <Box sx={{display: { xs: 'none', md: 'block', lg: 'none' }}}><img src={ImagemDestaqueTablet} style={{ position: 'absolute', bottom: '0px', right: '0px', height: '515px', width: '552px' }} alt="Imagem banner" /></Box>
        <Box sx={{display: { xs: 'block', md: 'none', lg: 'none' }}}><img src={ImagemDestaqueMobile} style={{ position: 'absolute', bottom: '0px', right: '0px', height: '270px', width: '289px' }} alt="Imagem banner" /></Box>
        <Container 
          maxWidth="xl"
          sx={{
            position: 'relative'
          }}>
          <Box
            sx={{
              position: 'absolute',
              top: { xs: '1vw', md: '6vw', lg: '10vw' },
              left: '0vw',
              width: { xs: '95%', md: 350, lg: 500 },
            }}>
            <Typography sx={{ 
              m: 1,
              fontSize: { xs: 18, md: 28, lg: 40 },
              color: '#ffffff',
              textTransform: 'uppercase',
            }}>Nos dedicamos ao crescimento do seu negócio.</Typography>

            <Divider sx={{ 
              m: 1, 
              fontSize: 40,
              backgroundColor:
                themeName === 'dark' ? '#cccccc' : '#dcbf81',
              width: 250,
              height: 5
            }}/>

            <Typography sx={{ 
              m: 1, 
              fontSize: { xs: 14, md: 16, lg: 20 },
              color:
                themeName === 'dark' ? '#cccccc' : '#dcbf81',
            }}>Na Balanço Pronto temos tudo o que é necessário para sua empresa reduzir a burocracia e despesas, com uma contabilidade inteligente feito por quem entende.</Typography>
            <Box sx={{mt: { xs: 24, md: 0, lg: 0 }}}>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'left'}}>
                <a href='#quemsomos'>
                  <Button variant="contained" color="branco">Conhecer Soluções</Button>
                </a>
                <a href='https://wa.me/5511989761201' target='blank'>
                  <IconButton aria-label="whatsapp" size="small" sx={{ml:1, backgroundColor: '#25a93e', color: '#ffffff'}}>
                    <WhatsAppIcon fontSize="large" />
                  </IconButton>
                </a>
              </div>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        width="100vw"
        sx={{
          backgroundColor:
            themeName === 'dark' ? '#000000' : '#16263b',
          position: 'relative',
          height: { xs: '10px', md: '10px', lg: '60px' },
        }}
      >
        <Stack
          direction="row"
          divider={<ArrowRightIcon orientation="vertical" sx={{ color: themeName === 'dark' ? '#ffffff' : '#dcbf81' }} />}
          spacing={{ xs: 0, md: 0, lg: 2 }}
          sx={{
            paddingTop: 1.5,
            display: { xs: 'none', md: 'none', lg: 'flex' },
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Item>Contabilidade Precisa</Item>
          <Item>Planejamento Tributário</Item>
          <Item>Consultoria Financeira</Item>
          <Item>Serviços de Folha de Pagamento</Item>
          <Item>Assessoria Empresarial</Item>
          <Item>Recuperação de crédito</Item>
        </Stack>
      </Box>
      <Box
        sx={{
          backgroundColor:
            themeName === 'dark' ? '#060c14' : '#f1f1f1',
          marginTop: { xs: '10px', md: '88px', lg: '88px' },
          paddingTop: { xs: '0px', md: '50px', lg: '50px' }
        }}
        id='quemsomos'>
        <Container 
          maxWidth="xl"
          sx={{
            backgroundColor:
              themeName === 'dark' ? '#1c1c1c' : '#ffffff'
          }}>
          <Box sx={{ position: 'relative', height: { xs: 'auto', md: '450px', lg: '450px' } }}>
            <Box sx={{ display: { xs: 'none', md: 'block', lg: 'block' }, position: { xs: 'relative', md: 'absolute', lg: 'absolute' }, top: { xs: 0, md: '-100px', lg: '-100px' }, right: '0' }}><img src={ImagemQuemSomos} width='410px' height='528px' alt="Balanço Pronto Quem Somos" /></Box>
            <Box sx={{ display: { xs: 'block', md: 'none', lg: 'none' }, position: { xs: 'relative', md: 'absolute', lg: 'absolute' }, top: { xs: 0, md: '-100px', lg: '-100px' }, right: '0', textAlign: 'center' }}><img src={ImagemQuemSomosMobile} width='250px' height='322px' alt="Balanço Pronto Quem Somos" /></Box>
            <Box sx={{ pt: 5, pl: 5 }} component="section">
              <Typography gutterBottom variant="h5" component="span" sx={{fontSize: { xs: '20px', md: '26px', lg: '34px' }, width: { xs: '90%', md: '60%', lg: '100%' }, fontWeight: '600', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Cultivamos a excelência em todos os quesitos
              </Typography>
              <List
                sx={{ width: { xs: '90%', md: '50%', lg: '60%' }, backgroundColor: themeName === 'dark' ? '#1c1c1c' : '#ffffff', mb: 3 }}
                aria-label="contacts">
                <ListItem disablePadding sx={{mt:2}}>
                  <ListItemIcon>
                    <StarIcon sx={{ color: themeName === 'dark' ? '#ffffff' : '#253b59' }} />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ fontSize: { xs: 14, md: 16, lg: 20 }, fontWeight: 'medium', color: themeName === 'dark' ? '#ffffff' : '#000000' }} primary="Atendimento via whatsapp. E-mail e Videoconferência." />
                </ListItem>
                <ListItem disablePadding sx={{mt:2}}>
                  <ListItemIcon>
                    <StarIcon sx={{ color: themeName === 'dark' ? '#ffffff' : '#253b59' }} />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ fontSize: { xs: 14, md: 16, lg: 20 }, fontWeight: 'medium', color: themeName === 'dark' ? '#ffffff' : '#000000' }} primary="Contador exclusivo para seu segmento." />
                </ListItem>
                <ListItem disablePadding sx={{mt:2}}>
                  <ListItemIcon>
                    <StarIcon sx={{ color: themeName === 'dark' ? '#ffffff' : '#253b59' }} />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ fontSize: { xs: 14, md: 16, lg: 20 }, fontWeight: 'medium', color: themeName === 'dark' ? '#ffffff' : '#000000' }} primary="Planos de acordo com o seu perfil." />
                </ListItem>
                <ListItem disablePadding sx={{mt:2}}>
                  <ListItemIcon>
                    <StarIcon sx={{ color: themeName === 'dark' ? '#ffffff' : '#253b59' }} />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ fontSize: { xs: 14, md: 16, lg: 20 }, fontWeight: 'medium', color: themeName === 'dark' ? '#ffffff' : '#000000' }} primary="Serviços Personalizados, ágeis e seguros." />
                </ListItem>
              </List>
              <Box sx={{display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', md: 'left', lg: 'left' }, pb: { xs: '20px', md: '0px', lg: '0px' }}}>
                <a href='#quemsomosaberto'>
                  <Button sx={{ 
                    backgroundColor:
                      themeName === 'dark' ? '#ffffff' : '#263c58',
                    color:
                    themeName === 'dark' ? '#000000' : '#dcbf81',
                    px: 4
                  }} onClick={() => setVisible(true)}>Quem Somos</Button>
                </a>
                <a href='https://wa.me/5511989761201' target='blank'>
                  <IconButton aria-label="whatsapp" size="small" sx={{ml:1, backgroundColor: '#25a93e', color: '#ffffff'}}>
                    <WhatsAppIcon fontSize="large" />
                  </IconButton>
                </a>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      {visible && (
        <Box
          sx={{
            backgroundColor:
              themeName === 'dark' ? '#060c14' : '#f1f1f1',
            marginTop: '10px',
            paddingTop: '10px'
          }}
          id='quemsomosaberto'>
          <Container 
            maxWidth="xl"
            sx={{
              backgroundColor:
                themeName === 'dark' ? '#1c1c1c' : '#ffffff',
              position: 'relative'
            }}>
            <IconButton aria-label="fechar" size="small" onClick={() => setVisible(false)} sx={{position: 'absolute', top: '10px', right: '10px', zIndex:10, color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
              <CloseIcon fontSize="large" />
            </IconButton>
            <Box sx={{ position: 'relative' }}>
              <Box sx={{ p: 5 }} component="section">
                <Typography gutterBottom variant={'h5'} component={'span'} sx={{fontSize: '16px', fontWeight: '400', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                No mundo complexo dos negócios, a gestão financeira eficaz é essencial para o sucesso a longo prazo. É aí que entramos em cena. Com orgulho, apresentamos nosso escritório contábil, um parceiro de confiança que está pronto para ajudar sua empresa a prosperar com experiência no mercado desde 1997.<br /><br />
                No coração do nosso escritório contábil está o compromisso com a excelência. Acreditamos que cada negócio é único e, portanto, oferecemos soluções sob medida para atender às necessidades específicas de nossos clientes. Seja você um empreendedor individual MEI ou ME, uma pequena empresa ou uma grande corporação, nossos serviços abrangentes são projetados para simplificar sua contabilidade, permitindo que você se concentre no crescimento do seu negócio.
                </Typography>
                <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '16px', fontWeight: '400', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Trabalhamos com os mais diversos tipos de segmentos empresariais, tanto de comércio como de serviços, com foco especial em empresas e profissionais de serviços como:
                </Typography>
                <Grid container spacing={4} sx={{mt:2, mb: 2}}>
                  <Grid item xs={12} md={6} 
                    sx={{
                      backgroundColor:
                        themeName === 'dark' ? '#000000' : '#f1f1f1',
                      border:
                        themeName === 'dark' ? '3px solid #1c1c1c' : '3px solid #ffffff',
                      position: 'relative',
                      p: 3,
                      textAlign: 'center',
                    }}>
                    <Typography gutterBottom variant="h5" component="span" sx={{color: themeName === 'dark' ? '#ffffff' : '#000000', fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                      Publicitários e agências de publicidade
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} 
                    sx={{
                      backgroundColor:
                        themeName === 'dark' ? '#000000' : '#f1f1f1',
                      border:
                        themeName === 'dark' ? '3px solid #1c1c1c' : '3px solid #ffffff',
                      position: 'relative',
                      p: 3,
                      textAlign: 'center',
                    }}>
                    <Typography gutterBottom variant="h5" component="span" sx={{color: themeName === 'dark' ? '#ffffff' : '#000000', fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                      Produtores, assessores e organizadores de eventos
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} 
                    sx={{
                      backgroundColor:
                        themeName === 'dark' ? '#000000' : '#f1f1f1',
                      border:
                        themeName === 'dark' ? '3px solid #1c1c1c' : '3px solid #ffffff',
                      position: 'relative',
                      p: 3,
                      textAlign: 'center',
                    }}>
                    <Typography gutterBottom variant="h5" component="span" sx={{color: themeName === 'dark' ? '#ffffff' : '#000000', fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                      Médicos e dentistas (clínicas e profissionais liberais)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} 
                    sx={{
                      backgroundColor:
                        themeName === 'dark' ? '#000000' : '#f1f1f1',
                      border:
                        themeName === 'dark' ? '3px solid #1c1c1c' : '3px solid #ffffff',
                      position: 'relative',
                      p: 3,
                      textAlign: 'center',
                    }}>
                    <Typography gutterBottom variant="h5" component="span" sx={{color: themeName === 'dark' ? '#ffffff' : '#000000', fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                      Veterinários (clínicas e profissionais liberais)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} 
                    sx={{
                      backgroundColor:
                        themeName === 'dark' ? '#000000' : '#f1f1f1',
                      border:
                        themeName === 'dark' ? '3px solid #1c1c1c' : '3px solid #ffffff',
                      position: 'relative',
                      p: 3,
                      textAlign: 'center',
                    }}>
                    <Typography gutterBottom variant="h5" component="span" sx={{color: themeName === 'dark' ? '#ffffff' : '#000000', fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                      Engenheiros, arquitetos e corretores de imóveis
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} 
                    sx={{
                      backgroundColor:
                        themeName === 'dark' ? '#000000' : '#f1f1f1',
                      border:
                        themeName === 'dark' ? '3px solid #1c1c1c' : '3px solid #ffffff',
                      position: 'relative',
                      p: 3,
                      textAlign: 'center',
                    }}>
                    <Typography gutterBottom variant="h5" component="span" sx={{color: themeName === 'dark' ? '#ffffff' : '#000000', fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                      Advogados e escritórios de advocacia
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} 
                    sx={{
                      backgroundColor:
                        themeName === 'dark' ? '#000000' : '#f1f1f1',
                      border:
                        themeName === 'dark' ? '3px solid #1c1c1c' : '3px solid #ffffff',
                      position: 'relative',
                      p: 3,
                      textAlign: 'center',
                    }}>
                    <Typography gutterBottom variant="h5" component="span" sx={{color: themeName === 'dark' ? '#ffffff' : '#000000', fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                      Programadores
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} 
                    sx={{
                      backgroundColor:
                        themeName === 'dark' ? '#000000' : '#f1f1f1',
                      border:
                        themeName === 'dark' ? '3px solid #1c1c1c' : '3px solid #ffffff',
                      position: 'relative',
                      p: 3,
                      textAlign: 'center',
                    }}>
                    <Typography gutterBottom variant="h5" component="span" sx={{color: themeName === 'dark' ? '#ffffff' : '#000000', fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                      Youtubers
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} 
                    sx={{
                      backgroundColor:
                        themeName === 'dark' ? '#000000' : '#f1f1f1',
                      border:
                        themeName === 'dark' ? '3px solid #1c1c1c' : '3px solid #ffffff',
                      position: 'relative',
                      p: 3,
                      textAlign: 'center',
                    }}>
                    <Typography gutterBottom variant="h5" component="span" sx={{color: themeName === 'dark' ? '#ffffff' : '#000000', fontSize: '16px', fontWeight: '400', textTransform: 'uppercase'}}>
                      Entre outras<br />(verifique com nosso atendimento)
                    </Typography>
                  </Grid>
                </Grid>
                <Typography gutterBottom variant="h5" component="span" sx={{fontSize: '16px', fontWeight: '400', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Nosso compromisso com a integridade, profissionalismo e sigilo é inabalável. Sabemos que a confiança é a base de qualquer parceria de sucesso, e estamos comprometidos em manter a mais alta ética em tudo o que fazemos.<br /><br />
                Aqui no nosso escritório, estamos prontos para ajudar você a alcançar seus objetivos financeiros e permitir que sua empresa prospere. Entre em contato conosco hoje e descubra como podemos simplificar suas operações contábeis e liberar seu tempo para se concentrar no que realmente importa - o crescimento do seu negócio.<br /><br />
                Confie em nós como seu parceiro de contabilidade e embarque em uma jornada de sucesso financeiro. Estamos ansiosos para trabalhar com você.
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
      )}

      <Box
        sx={{
          backgroundColor:
            themeName === 'dark' ? '#060c14' : '#f1f1f1'
        }}
        id='servicos'>
        <Container 
          maxWidth="xl"
          sx={{display: { xs: 'block', md: 'block', lg: 'flex' }, p: 5}}>
          <Typography gutterBottom variant="h5" component="span" sx={{
            fontSize: { xs: '24px', md: '28px', lg: '34px' }, 
            fontWeight: '600', 
            width: { xs: '100%', md: '100%', lg: '470px' }, 
            color: themeName === 'dark' ? '#ffffff' : '#060c14',
            display: 'block'}}>
            Confira as melhores soluções para seu negócio
          </Typography>
          <Typography gutterBottom variant="h5" component="span" sx={{
            fontSize: { xs: '16px', md: '18px', lg: '24px' }, 
            fontWeight: '300', 
            width: { xs: '100%', md: '100%', lg: '780px' },
            p: { xs: 0, md: 0, lg: 2 }, 
            ml: { xs: '0', md: '0', lg: '200px' }, 
            color: themeName === 'dark' ? '#ffffff' : '#060c14',
            display: 'block'}}>
            Com a Balanço Pronto você terá as soluções perfeitas para o seu negócio com um investimento muito mais acessível.
          </Typography>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor:
            themeName === 'dark' ? '#060c14' : '#f1f1f1'
        }}>
        <Container 
          maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={4} 
              sx={{
                backgroundColor:
                  themeName === 'dark' ? '#1c1c1c' : '#ffffff',
                border:
                  themeName === 'dark' ? '3px solid #060c14' : '3px solid #f1f1f1',
                position: 'relative',
                pb: 5,
                pr: 2
              }}>
              {themeName === 'light' && (<img src={TopoBox} style={{margin: 'auto'}} width='90%' height='19px' alt="Balanço Pronto Separador" />)}
              {themeName === 'dark' && (<img src={TopoBoxDark} style={{margin: 'auto'}} width='90%' height='19px' alt="Balanço Pronto Separador" />)}
              <Typography gutterBottom variant="h5" component="span" sx={{display: 'block', fontSize: '24px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Contabilidade precisa
              </Typography>
              <Typography gutterBottom variant="h5" component="span" sx={{display: 'block', fontSize: '14px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Mantemos registros financeiros impecáveis e relatórios atualizados para garantir que você esteja em conformidade com todas as regulamentações fiscais.
              </Typography>
              <a href='https://wa.me/5511989761201' target='blank'>
                <IconButton aria-label="whatsapp" size="small" sx={{ml:1, backgroundColor: '#25a93e', color: '#ffffff', position:'absolute', bottom: 10, right: 10}}>
                  <WhatsAppIcon fontSize="large" />
                </IconButton>
              </a>
            </Grid>
            <Grid item xs={12} md={6} lg={4} 
              sx={{
                backgroundColor:
                  themeName === 'dark' ? '#1c1c1c' : '#ffffff',
                border:
                  themeName === 'dark' ? '3px solid #060c14' : '3px solid #f1f1f1',
                position: 'relative',
                pb: 5,
                pr: 2
              }}>
              {themeName === 'light' && (<img src={TopoBox} style={{margin: 'auto'}} width='90%' height='19px' alt="Balanço Pronto Separador" />)}
              {themeName === 'dark' && (<img src={TopoBoxDark} style={{margin: 'auto'}} width='90%' height='19px' alt="Balanço Pronto Separador" />)}
              <Typography gutterBottom variant="h5" component="span" sx={{display: 'block', fontSize: '24px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Planejamento tributário
              </Typography>
              <Typography gutterBottom variant="h5" component="span" sx={{display: 'block', fontSize: '14px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Trabalhamos incansavelmente para otimizar sua carga tributária, economizando dinheiro e garantindo que você cumpra todas as obrigações fiscais.
              </Typography>
              <a href='https://wa.me/5511989761201' target='blank'>
                <IconButton aria-label="whatsapp" size="small" sx={{ml:1, backgroundColor: '#25a93e', color: '#ffffff', position:'absolute', bottom: 10, right: 10}}>
                  <WhatsAppIcon fontSize="large" />
                </IconButton>
              </a>
            </Grid>
            <Grid item xs={12} md={6} lg={4} 
              sx={{
                backgroundColor:
                  themeName === 'dark' ? '#1c1c1c' : '#ffffff',
                border:
                  themeName === 'dark' ? '3px solid #060c14' : '3px solid #f1f1f1',
                position: 'relative',
                pb: 5,
                pr: 2
              }}>
              {themeName === 'light' && (<img src={TopoBox} style={{margin: 'auto'}} width='90%' height='19px' alt="Balanço Pronto Separador" />)}
              {themeName === 'dark' && (<img src={TopoBoxDark} style={{margin: 'auto'}} width='90%' height='19px' alt="Balanço Pronto Separador" />)}
              <Typography gutterBottom variant="h5" component="span" sx={{display: 'block', fontSize: '24px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Consultoria financeira
              </Typography>
              <Typography gutterBottom variant="h5" component="span" sx={{display: 'block', fontSize: '14px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Oferecemos orientação estratégica para ajudar a impulsionar seu negócio, desde a gestão de fluxo de caixa até a análise de investimentos.
              </Typography>
              <a href='https://wa.me/5511989761201' target='blank'>
                <IconButton aria-label="whatsapp" size="small" sx={{ml:1, backgroundColor: '#25a93e', color: '#ffffff', position:'absolute', bottom: 10, right: 10}}>
                  <WhatsAppIcon fontSize="large" />
                </IconButton>
              </a>
            </Grid>
            <Grid item xs={12} md={6} lg={4} 
              sx={{
                backgroundColor:
                  themeName === 'dark' ? '#1c1c1c' : '#ffffff',
                border:
                  themeName === 'dark' ? '3px solid #060c14' : '3px solid #f1f1f1',
                position: 'relative',
                pb: 5,
                pr: 2
              }}>
              {themeName === 'light' && (<img src={TopoBox} style={{margin: 'auto'}} width='90%' height='19px' alt="Balanço Pronto Separador" />)}
              {themeName === 'dark' && (<img src={TopoBoxDark} style={{margin: 'auto'}} width='90%' height='19px' alt="Balanço Pronto Separador" />)}
              <Typography gutterBottom variant="h5" component="span" sx={{display: 'block', fontSize: '24px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Serviços de folha de pagamento
              </Typography>
              <Typography gutterBottom variant="h5" component="span" sx={{display: 'block', fontSize: '14px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Garantimos que seus funcionários sejam pagos corretamente e a tempo, mantendo você em conformidade com as leis trabalhistas.
              </Typography>
              <a href='https://wa.me/5511989761201' target='blank'>
                <IconButton aria-label="whatsapp" size="small" sx={{ml:1, backgroundColor: '#25a93e', color: '#ffffff', position:'absolute', bottom: 10, right: 10}}>
                  <WhatsAppIcon fontSize="large" />
                </IconButton>
              </a>
            </Grid>
            <Grid item xs={12} md={6} lg={4} 
              sx={{
                backgroundColor:
                  themeName === 'dark' ? '#1c1c1c' : '#ffffff',
                border:
                  themeName === 'dark' ? '3px solid #060c14' : '3px solid #f1f1f1',
                position: 'relative',
                pb: 5,
                pr: 2
              }}>
              {themeName === 'light' && (<img src={TopoBox} style={{margin: 'auto'}} width='90%' height='19px' alt="Balanço Pronto Separador" />)}
              {themeName === 'dark' && (<img src={TopoBoxDark} style={{margin: 'auto'}} width='90%' height='19px' alt="Balanço Pronto Separador" />)}
              <Typography gutterBottom variant="h5" component="span" sx={{display: 'block', fontSize: '24px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Assessoria Empresarial
              </Typography>
              <Typography gutterBottom variant="h5" component="span" sx={{display: 'block', fontSize: '14px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Compreendemos os desafios únicos que as empresas enfrentam e fornecemos insights valiosos para o crescimento e a sustentabilidade do seu negócio.
              </Typography>
              <a href='https://wa.me/5511989761201' target='blank'>
                <IconButton aria-label="whatsapp" size="small" sx={{ml:1, backgroundColor: '#25a93e', color: '#ffffff', position:'absolute', bottom: 10, right: 10}}>
                  <WhatsAppIcon fontSize="large" />
                </IconButton>
              </a>
            </Grid>
            <Grid item xs={12} md={6} lg={4} 
              sx={{
                backgroundColor:
                  themeName === 'dark' ? '#1c1c1c' : '#ffffff',
                border:
                  themeName === 'dark' ? '3px solid #060c14' : '3px solid #f1f1f1',
                position: 'relative',
                pb: 5,
                pr: 2
              }}>
              {themeName === 'light' && (<img src={TopoBox} style={{margin: 'auto'}} width='90%' height='19px' alt="Balanço Pronto Separador" />)}
              {themeName === 'dark' && (<img src={TopoBoxDark} style={{margin: 'auto'}} width='90%' height='19px' alt="Balanço Pronto Separador" />)}
              <Typography gutterBottom variant="h5" component="span" sx={{display: 'block', fontSize: '24px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Recuperação de crédito
              </Typography>
              <Typography gutterBottom variant="h5" component="span" sx={{display: 'block', fontSize: '14px', fontWeight: '300', color: themeName === 'dark' ? '#ffffff' : '#000000'}}>
                Nossa equipe de especialistas em recuperação identifica e recupera créditos fiscais não aproveitados, injetando capital vital em sua empresa.
              </Typography>
              <a href='https://wa.me/5511989761201' target='blank'>
                <IconButton aria-label="whatsapp" size="small" sx={{ml:1, backgroundColor: '#25a93e', color: '#ffffff', position:'absolute', bottom: 10, right: 10}}>
                  <WhatsAppIcon fontSize="large" />
                </IconButton>
              </a>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor:
            themeName === 'dark' ? '#060c14' : '#f1f1f1',
          pt: 5,
          pb: 8
        }}>
        <Container 
          maxWidth="xl">
          <Box
            sx={{
              backgroundColor:
                themeName === 'dark' ? '#333333' : '#263c58',
              position: 'relative',
              p: { xs: 3, md: 8, lg: 8 }
            }}>
            <Typography sx={{color: '#ffffff', fontSize: { xs: 20, md: 20, lg: 24 }, width: { xs: '100%', md: '300px', lg: '432px' }}}>
              Estamos presente em todo o Estado para auxiliar você e seu negócio da melhor maneira.
            </Typography>
            <Box sx={{
              position: { xs: 'relative', md: 'absolute', lg: 'absolute' }, 
              top: { xs: 'auto', md: '-25px', lg: '-25px' }, 
              right: { xs: 'auto', md: '2%', lg: '2%' },
              textAlign: 'center',
              margin: 'auto'}}><img src={MapaSaoPaulo} width='90%' height='auto' alt="Balanço Pronto Mapa" /></Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor:
            themeName === 'dark' ? '#000000' : '#263c58',
          pt: 5,
          pb: 3,
          overflow: 'hidden'
        }}>
        <Container 
          maxWidth="xl">
          <Grid container spacing={0}>
            <Grid item xs={12} md={4}>
              <Box sx={{pt: 2, borderBottom: '2px solid #ffffff', width: 300}}><img src={LogoRodape} width='142px' height='55px' alt="Balanço Pronto Logo Rodape" /></Box>
              <Typography sx={{color: '#ffffff', fontSize: 12, backgroundColor: themeName === 'dark' ? '#000000' : '#263c58', position: 'relative', pt: 2}}>
                Tenha a sua contabilidade mensal sem se preocupar.
              </Typography>
              <div style={{display: 'flex'}}>
                <IconButton aria-label="Instagram" size="large" sx={{ml:1, color: '#ffffff'}}>
                  <InstagramIcon fontSize="large" />
                </IconButton>
                <a href='https://wa.me/5511989761201' target='blank'>
                  <IconButton aria-label="whatsapp" size="large" sx={{ml:1, color: '#ffffff'}}>
                    <WhatsAppIcon fontSize="large" />
                  </IconButton>
                </a>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{color: '#ffffff', fontSize: 18}}>
                FALE CONOSCO
              </Typography>
              <Typography sx={{color: '#ffffff', fontSize: 14, mt:2}}>
                Rua Aureliano Guimarães, 150, conjunto 315<br />Vila Andrade, São Paulo – SP
              </Typography>
              <Typography sx={{color: '#ffffff', fontSize: 14, mt:2}}>
                atendimento@balancopronto.com.br
              </Typography>
              <a href='https://wa.me/5511989761201' target='blank' style={{textDecoration: 'none'}}>
                <Typography sx={{color: '#ffffff', fontSize: 14, mt:2}}>
                  11 9 8976 1201
                </Typography>
              </a>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{color: '#ffffff', fontSize: 18}}>
                SOBRE
              </Typography>
              <Typography sx={{color: '#ffffff', fontSize: 14, mt:2}}>
                Acreditamos que cada negócio é único e, portanto, oferecemos soluções sob medida para atender as necessidades específicas de nossos clientes.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};